<!--
 * @Author: xiaziwen
 * @Date: 2021-01-08 09:27:59
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-08 10:20:14
 * @FilePath: \crm-education\src\components\business\course\Discuss.vue
-->
<template>
  <a-modal width="650px" class="cus-modal course-download-dialog" v-model="visible" :footer="false">
    <template slot="title">
      <p class="text-center">形考册子下载</p>
    </template>
    <div class="file-list">
      <a-list item-layout="horizontal" :data-source="dataList"  disabled="true">
        <a-list-item slot="renderItem" slot-scope="item">
          <div class="d-flex justify-between align-center w-full">
            <p style="width: 80%">【{{ item.courseName }}】{{ item.dataName }}</p>
          </div>
          <a slot="actions" title="下载" :href="item.dataUrl" download ><a-icon type="download" /> </a>
          <a slot="actions" title="预览" target="_blank" :href="getPdfUrl(item)"><a-icon type="file-text" /></a>
        </a-list-item>
      </a-list>
      <a-list item-layout="horizontal" :data-source="gradeList"  disabled="true" v-if="gradeList.length">
        <a-list-item slot="renderItem" slot-scope="item">
          <div class="d-flex justify-between align-center w-full">
            <p style="margin-left: 10px">{{item.shape_grade ==null?"":'成绩：'+item.shape_grade+'分'}} </p>
          </div>
        </a-list-item>
        <!--<a-upload name="file" :customRequest="handleUpload" :fileList="fileList" :remove="removeFile">
          <a-button> <a-icon type="upload"/>上传</a-button>
        </a-upload>-->
      </a-list>
    </div>
  </a-modal>
</template>

<script>
import api from '@/api'
import AListItem from "ant-design-vue/es/list/Item";
import helper from '@/utils/helper'
export default {
  name: 'shape_donwload_dialog',
  components: {AListItem},
  data() {
    return {
      visible: false,
      dataList: [],
      gradeList:[],
      fileList:[],
      paginationObject: {
        current: 1,
        total: 0,
        pageSize: 5,
        onChange: this.onPageChange
      },
      info: {}
    }
  },
  methods: {
    async open(info) {
      this.info = info
      await this.getShapeBookInfo(this.info)
      await this.getShapeGradeInfo(this.info)
      this.visible = true
    },
    onPageChange(page, pageSize) {
      this.paginationObject.current = page
      this.getShapeBookInfo(this.info)
    },
    async getShapeBookInfo(info) {
      const courseId = info.courseId || '888888'
      let res = await api.student.getShapeBookInfo({
        courseId: courseId,
        pageSize: this.paginationObject.pageSize,
        currentPage: this.paginationObject.current
      })
      if (res.code === 200) {
        this.dataList = res.data
      }
    },
    async getShapeGradeInfo(info) {
      const courseId = info.courseId || '888888'
      let res = await api.student.getShapeGradeInfo({
        courseId: courseId,
      })
      if (res.code === 200 && res.data!=null) {
        const file = {
          uid: res.data.course_id,
          name: res.data.shape_file_name,
          status: 'done',
          url: res.data.shape_file_url
        }
        this.gradeList = [];
        this.gradeList.push(res.data)
        this.fileList = [];
        this.fileList.push(file)
      }
    },

    async handleUpload(file) {
      const formData = new FormData()
      formData.append('file', file.file)
      formData.append('studId', helper.getUserInfo('userId'))
      formData.append('courseId', this.info.courseId)
      formData.append('termId', this.info.termId)
      const  data  = await api.student.uploadShapeGradeFile(formData)
      this.fileList = [
        {
          uid: file.file.uid,
          name: file.file.name,
          status: 'done',
          url: data
        }
      ];
      await this.getShapeGradeInfo(this.info);
    },
    getPdfUrl(item){
      return 'http://ow365.cn/?info=3&i=25157&furl='+item.dataUrl;
    },
    async removeFile(){
      const formData = new FormData()
      formData.append('gradeId', this.gradeList[0].grade_id)
      const res = await api.student.deleteShapeGradeFile(formData);
      if (res.code === 200) {
        this.fileList=[];
        this.gradeList=[];
        await this.getShapeGradeInfo(this.info)
        return true;
      }else{
        return false;
      }
    }
  }
}
</script>
