<template>
  <a-modal width="620px" class="cus-modal course-authorization-dialog" v-model="visible" :footer="false">
    <p class="text-center fs-18 py-10" slot="title">国开学习网-账号密码授权登录</p>
    <div class="d-flex col justify-center pl-30">
      <div class="d-flex align-center my-10">
        <div class="label text-right fw-bold fs-18"><span class="required mr-10"></span>账号</div>
        <div class="content pl-20">
          <p class="fs-16">{{ formData.studNo }}</p>
        </div>
      </div>
      <div class="d-flex align-center my-10">
        <div class="label text-right fw-bold fs-18"><span class="required mr-10"></span>登录密码</div>
        <div class="d-flex align-center pl-20">
          <template v-if="editable">
            <a-input v-model="formData.ouchPassword" size="small"></a-input>
            <a-button size="small" @click="editable = false">取消</a-button>
          </template>
          <template v-else>
            <p class="fs-16 mr-10">{{ formData.ouchPassword }}</p>
            <a-button type="primary" size="small" @click="editable = true">修改密码</a-button>
          </template>
        </div>
      </div>
    </div>
    <div class="d-flex align-center p-10">
      <a-icon type="exclamation-circle" />
      <p class="color-gray fs-12 pl-10">
        为了给您提供便捷的登录学习服务，您可同意授权本平台使用国开学习网（http://www.ouchn.cn）的账号密码快捷登录进入学习
        。
      </p>
    </div>
    <div class="d-flex align-center p-10">
      <a-icon type="exclamation-circle" />
      <p class="color-gray fs-12 pl-10">
        以上账号密码为国开学习网的默认账号密码，如您已在国开学习网修改过密码，请点击“修改”按钮同步修改为最新密码 。
      </p>
    </div>
    <div class="text-center">
      <a-button class="mx-10" type="primary" size="small" @click="onAgree">同意，快捷进入学习</a-button>
      <a-button class="mx-10" size="small" @click="onUnAgree">不同意，自行登录</a-button>
    </div>
  </a-modal>
</template>

<script>
import api from '@/api'
export default {
  name: 'course_authorization_dialog',
  data() {
    return {
      visible: false,
      editable: false,
      formData: {
        studNo: '',
        ouchPassword: ''
      },
      studId: localStorage.getItem('userId'),
      organiztionId: localStorage.getItem('organizationId'),
      info: {}
    }
  },
  methods: {
    open(info = {}) {
      this.info = info
      this.studId = info.stud_id || this.studId
      this.init()
      this.visible = true
    },
    async init() {
      const params = {
        forId: this.info.forId,
        studId: this.studId
      }
      let res = await api.student.getStudentOuchPassword(params)
      if (res.code === 200) {
        this.formData.ouchPassword = this.parseOuchPassword(res.data.ouchPassword)
        this.formData.studNo = res.data.studNo
      }
    },
    parseOuchPassword(val) {
      let str = []
      if (typeof val === 'string') {
        str = val.split('').filter((item, index) => {
          return index % 2 === 0
        })
      }
      return str.join('')
    },
    onAgree() {
      const data = {
        forId: this.info.forId,
        ouchPassword: this.formData.ouchPassword,
        studId: this.studId,
        organiztionId:this.organiztionId
      }
      this.$emit('on-confirm', data)
      this.visible = false
    },
    onUnAgree() {
      window.location.href = 'http://www.ouchn.cn'
    }
  }
}
</script>

<style lang="scss" scoped>
.course-authorization-dialog {
  .label {
    width: 120px;
  }
  .content {
    flex: 1;
  }
}
</style>
