<!--
 * @Author: xiaziwen
 * @Date: 2021-01-08 09:27:59
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-08 10:20:14
 * @FilePath: \crm-education\src\components\business\course\Discuss.vue
-->
<template>
  <a-modal width="650px" class="cus-modal course-download-dialog" v-model="visible" :footer="false">
    <template slot="title">
      <p class="text-center">学习资料下载</p>
    </template>
    <div class="file-list">
      <a-list item-layout="horizontal" :data-source="dataList" :pagination="paginationObject">
        <a-list-item slot="renderItem" slot-scope="item">
          <div class="d-flex justify-between align-center w-full">
            <p style="width: 80%">【{{ item.courseName }}】{{ item.dataName }}</p>
          <div>
            <a :href="item.dataUrl" download style="margin-left: 80px"><a-icon type="download" /> </a>
            <a target="_blank" :href="getPdfUrl(item)" style="margin-left: 10px"><a-icon type="file-text" /></a>
          </div>
          </div>
        </a-list-item>
      </a-list>
    </div>
  </a-modal>
</template>

<script>
import api from '@/api'
export default {
  name: 'course_donwload_dialog',
  data() {
    return {
      visible: false,
      dataList: [],
      paginationObject: {
        current: 1,
        total: 0,
        pageSize: 5,
        onChange: this.onPageChange
      },
      info: {}
    }
  },
  methods: {
    async open(info) {
      this.info = info
      await this.getDownloadInfo(this.info)
      this.visible = true
    },
    onPageChange(page, pageSize) {
      this.paginationObject.current = page
      this.getDownloadInfo(this.info)
    },
    async getDownloadInfo(info) {
      const courseId = info.courseId || '888888'
      let res = await api.student.getCourseData({
        courseId: courseId,
        pageSize: this.paginationObject.pageSize,
        currentPage: this.paginationObject.current
      })
      if (res.code === 200) {
        this.dataList = res.data
      }
    },
    getPdfUrl(item){
      return 'http://ow365.cn/?info=3&i=25157&furl='+item.dataUrl;
    }
  }
}
</script>
