<!--
 * @Author: xiaziwen
 * @Date: 2021-01-07 15:12:29
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-13 13:55:26
 * @FilePath: \crm-education\src\components\business\course\Item.vue
-->
<template>
  <div class="course-item pc-flex justify-between align-center">
    <div class="course-info d-flex">
      <div class="course-img" :style="{ backgroundImage: 'url(' + (item.courseImg ? item.courseImg : '') + ')' }"></div>
      <div class="px-10 d-flex col justify-center course-content">
        <div class="fw-bold title fs-18 mb-10">{{ item.courseName }}</div>
        <div class="fs-14 content color-gray">
          {{ item.courseCredit }}学分 {{ item.examCompany == 1 ? '中央' : '省' }}
        </div>
      </div>
    </div>
    <div>
      <div class="tags d-flex flex-wrap align-center">
        <div class="course-tag cs d-flex justify-center align-center">
          <div class="tag-bg"></div>
          <div class="info">
            <p class="fs-16 fw-bold">{{ item.learningCount || '- -' }}</p>
            <p class="fs-14">学习次数</p>
          </div>
        </div>
        <div class="course-tag cj d-flex justify-center align-center">
          <div class="tag-bg"></div>
          <div class="info">
            <p class="fs-16 fw-bold">{{ item.learnScore || '- -' }}</p>
            <p class="fs-14">学习成绩</p>
          </div>
        </div>
        <div class="course-tag zt d-flex justify-center align-center">
          <div class="tag-bg"></div>
          <div class="info">
            <p class="fs-16 fw-bold">{{ item.learnState | getLearnStatusTxt }}</p>
            <p class="fs-14">课程状态</p>
          </div>
        </div>
      </div>
      <div style="width: 450px;white-space:pre-wrap">
        <p class="required pl-5 mt-10 fs-12" v-if="item.courseDescription">{{ item.courseDescription }}</p>
      </div>
    </div>

    <div class="course-button">
      <a-button type="primary" class="cus-button" @click="onClick(item.goMyGraduat)" v-if="item.goStudyState == 1">进入学习</a-button>
      <a-button type="primary" class="cus-button" disabled v-else-if="item.goStudyState == 2">暂未开放</a-button>
      <a-button type="primary" class="cus-button" disabled v-else-if="item.goStudyState == 3">已关闭</a-button>
    </div>
    <course-navigation-dialog ref="courseNavigationDialog"></course-navigation-dialog>
  </div>
</template>

<script>
import CourseNavigationDialog from './Navigation'
export default {
  name: 'course_item',
  components: {
    CourseNavigationDialog
  },
  props: {
    item: {
      type: Object,
      defualt: () => ({})
    },
    active: {
      type: [String, Number]
    }
  },
  methods: {
    onClick(goMyGraduat) {
      if(goMyGraduat== 'Y'){
        this.$router.push({name:'student_my_graduat',params:{'isRoll':true}})
        //this.$router.push('/student/my_graduat')
        return;
      }

      const info = {
        ...this.item,
        termId: this.active
      }
      this.$refs.courseNavigationDialog.open(info)
    }
  },
  filters: {
    getLearnStatusTxt(val) {
      switch (Number(val)) {
        case 1:
          return '未学习'
        case 2:
          return '学习中'
        case 3:
          return '待登记'
        case 4:
          return '已通过'
        case 5:
          return '未通过'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.course-item {
  width: 100%;
  padding: 20px;
  transition: all 0.5s;
  &:hover {
    border-color: rgba(0, 0, 0, 0.09);
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.09);
    border-radius: 4px;
    overflow: hidden;
  }
  .course-info {
    overflow: hidden;
    display: flex;
    .course-content {
      text-align: left;
      width: 180px;
      > .title {
        width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        align-items: flex-start;
        overflow: hidden;
      }
    }
  }
  .course-img {
    width: 160px;
    height: 96px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #e74e3e;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .tags {
    .course-tag {
      width: 140px;
      height: 64px;
      border-radius: 3px;
      overflow: hidden;
      margin: 0 3px;
      .tag-bg {
        width: 40px;
        height: 28px;
      }
      &.cs {
        background-color: #f5f7ff;
        .tag-bg {
          background: url('../../../assets/img/course-cs.png') no-repeat center center;
        }
      }
      &.cj {
        background-color: #fff5f5;
        .tag-bg {
          background: url('../../../assets/img/course-cj.png') no-repeat center center;
        }
      }
      &.zt {
        background-color: #fff9f5;
        .tag-bg {
          background: url('../../../assets/img/course-zt.png') no-repeat center center;
        }
      }
      .info {
        padding: 0 6px;
        text-align: center;
      }
    }
  }
}

/*响应式布局设置 当屏幕尺寸大于480px时，应用下面的CSS样式, 1rem = 25.6px */
@media only screen and (min-width:480px) {
  .pc-flex {
    display: flex;
  }
}
</style>
