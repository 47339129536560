<template>
    <a-modal  :visible="wxPublicVisible" :footer="false" width="320px" @cancel="wxPublicVisible = false">
        <!-- 放置二维码的div -->
        <div >
            <img src="@/assets/img/jsz-wx-public.jpg" width="100%"/>
            <p>请关注公众号“广州开放大学金沙洲分校”，关注成功后次日将开放学习资料下载权限！</p>
        </div>
    </a-modal>
</template>

<script>
    export default {
        name: 'wx_public_account',
        data() {
            return {
                wxPublicVisible: false,
            }
        },
        methods: {
            // 打开
            async open() {
                this.wxPublicVisible = true;
            },
        }
    }
</script>