<template>
    <div>
        <a-modal :visible="accountVisible" @cancel="accountVisible = false" :footer="false" width="40%">
            <div class="section-header dec">
                <h2 class="base-title fs-18">关联第三方账号</h2>
                <span style="color: red;position: relative;top: 10px;">*关联后可使用第三方账号直接登录学习空间</span>
                <div style="border-bottom: 1px solid rgb(215, 220, 220);margin-top: 30px;"></div>
            </div>
            <a-list :content="accountData">
                <a-list-item >
                    <p class="py-20 fs-16"><img src="@/assets/img/login/wx.png" alt="" style="width: 30px"/>微信</p>
                    <p class="py-20 fs-16">{{ accountData.isRelation === 'Y' ? '已关联'+ '('+ accountData.nickname + '）' : '未关联' }}</p>
                    <div class="py-20">
                        <a-button class="cus-button" type="primary" v-show="accountData.isRelation === 'Y'" @click.native= "unbound">解除关联</a-button>
                        <a-button class="cus-button" type="primary" v-show="accountData.isRelation === 'N'"  @click.native= "binding">关联</a-button>
                    </div>
                </a-list-item>
            </a-list>
        </a-modal>
        <a-modal  :visible="wxLoginVisible"
                  :footer="false"
                  width="350px"
                  :maskClosable="false"
                  @cancel="wxLoginVisible = false">
            <!-- 放置二维码的div -->
            <div id="login_container">
                <iframe ></iframe>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import api from '@/api'
    export default {
        name: "wx_relation_dialog",
        data() {
            return {
                accountVisible: false,
                accountData: {},
                wxLoginVisible: false,
            }
        },

        methods: {
            // 打开
            async open() {
                this.openRelationAccount();
            },
            // 绑定
            async binding() {
                //生成微信登录二维码
                this.wechatCode()
                this.wxLoginVisible = true
            },
            // 生成微信登录二维码
            async wechatCode () {
                let organizationId = localStorage.getItem('organizationId');
                let url = process.env.VUE_APP_WX_API_BASE_URL + "/student/redirect?organizationId=" + organizationId + "&type=downloadData&env=" + process.env.NODE_ENV;
                let wxAppId = process.env.VUE_APP_WX_APP_ID;
                //记住一定要注明文件类型是css
                var blob = new Blob([""],{type: "text/css;charset=utf-8"});
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = function(e) {
                    var obj = new WxLogin({
                        self_redirect: false,  //值为true时，直接在二维码的弹窗中进行页面跳转及其余操作,
                        id:"login_container", //div的id
                        appid: wxAppId,
                        scope: "snsapi_login", //固定内容
                        redirect_uri:encodeURIComponent(url) //回调地址
                    })
                }
            },
            // 解绑微信账号
            unbound() {
                let self = this
                let data = this.accountData
                this.$confirm({
                    title: '是否解除与该微信账号的关联关系？',
                    content: '',
                    okText: '是',
                    cancelText: '否',
                    onOk() {
                        api.student.unboundData(data).then(res => {
                            if (res.code === 200) {
                                self.$message.success("解除成功! ")
                                self.openRelationAccount()
                            } else {
                                self.$message.error("解除失败：" + res.data)
                            }
                        })
                    },
                    onCancel() {
                    },
                });
            },
            // 绑定账号
            async bindingAccount(code,state) {
                this.accountData.code = code
                let res = await api.student.bindingAccount(this.accountData)
                if (res.code === 200) {
                    this.$router.push({ query: {} });
                    this.wxLoginVisible = false;
                    this.openRelationAccount();
                    this.$message.success("关联成功！请再次点击学习资料入口，按提示关注公众号！",7)
                }
            },
            // 打开关联信息界面
            async openRelationAccount() {
                this.accountVisible = true
                let res = await api.student.getRelationAccountData()
                this.accountData = res.data
            },
        },
    }
</script>