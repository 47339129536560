<!--
 * @Author: xiaziwen
 * @Date: 2021-01-07 14:31:16
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-09 12:03:21
 * @FilePath: \crm-education\src\views\student\home\c\MyCourse.vue
-->
<template>
  <a-card class="cus-card my-course no-border" :bordered="false">
    <template slot="title">
      <div class="section-header dec d-flex align-center justify-between flex-wrap">
        <h2 class="base-title fs-18">我的选课</h2>
        <!-- <p class="fs-14 color-gray link" @click="$router.push({ name: 'student_my_study' })">
          查看更多
        </p> -->
      </div>
    </template>
    <div>
      <a-tabs v-model="active" class="cus-tabs">
        <a-tab-pane :key="term.termId" v-for="term in termList">
          <template slot="tab">
            <div class="active" v-if="term.currentTeaching === 'Y'">
              <p class="fs-18">{{ term.termName }}</p>
              <span class="active-mark">当前</span>
            </div>
            <p class="fs-18" v-else>{{ term.termName }}</p>
          </template>
        </a-tab-pane>
      </a-tabs>
      <div class="course-detail">
        <div class="course-detail_header d-flex justify-between align-center">
          <p class="fs-14 color-gray">学期时间{{ courseInfo.termStartTime }} 至 {{ courseInfo.termEndTime }}</p>
          <div class="d-flex jusitify-end align-center">
            <p class="fs-14 color-gray px-10">
              总选课<span class="fs-18 fw-bold color-black px-5">{{ courseInfo.allSelectCount }}</span>
            </p>
            <p class="fs-14 color-gray px-10">
              未学习<span class="fs-18 fw-bold color-black px-5">{{ courseInfo.noLearnCount }}</span>
            </p>
            <p class="fs-14 color-gray px-10">
              学习中<span class="fs-18 fw-bold color-black px-5">{{ courseInfo.learnCount }}</span>
            </p>
            <p class="fs-14 color-gray px-10">
              登记中<span class="fs-18 fw-bold color-black px-5">{{ courseInfo.registrationCount }}</span>
            </p>
          </div>
        </div>
        <a-list class="course-box" item-layout="horizontal" :data-source="courseInfo.selectCourses" :split="false">
          <a-list-item slot="renderItem" slot-scope="item, index">
            <course-item :item="item" :index="index" :active="active"></course-item>
          </a-list-item>
        </a-list>
      </div>
    </div>
  </a-card>
</template>

<script>
import CourseItem from '@/components/business/course/Item'
export default {
  name: 'home_my_course',
  components: {
    CourseItem
  },
  props: {
    activeKey: {
      type: [String, Number],
      default: '1'
    },
    termList: {
      type: Array
    },
    courseInfo: {
      type: Object
    }
  },
  computed: {
    active: {
      get() {
        return this.activeKey || '1'
      },
      set(val) {
        this.$emit('update:activeKey', val)
        this.$emit('refresh', val)
      }
    }
  },
  data() {
    return {
      dataList: [{}, {}, {}]
    }
  }
}
</script>

<style lang="scss" scope>
.my-course {
  .ant-tabs {
    .ant-tabs-nav {
      padding-top: 13px;
      .active {
        position: relative;
        .active-mark {
          position: absolute;
          width: 40px;
          height: 30px;
          line-height: 22px;
          right: -10px;
          top: -25px;
          font-size: 10px;
          background: url('../../../../assets/img/dq-bg.png') no-repeat center center;
        }
      }
    }
  }
}
.course-box {
  margin: 0 -10px;
}
</style>
