<!--
 * @Author: xiaziwen
 * @Date: 2021-01-07 11:51:13
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-02-03 17:05:47
 * @FilePath: \crm-education\src\components\business\panel\Live.vue
-->
<template>
  <a-card class="panel-card" hoverable style="width: 240px">
    <div class="live-panel" slot="cover">
      <div
        class="detail d-flex col align-center justify-center"
        :style="{ backgroundImage: 'url(' + (options.liveImg ? options.liveImg : '') + ')' }"
      >
       <div v-show="isShow">
        <p class="title fs-18 mb-15 fw-bold">{{ options.liveName }}</p>
        <p class="sub-author fs-14 fw-bold">主讲教师：{{ options.anchorTeacherName }}</p>
       </div>
      </div>
      <div class="meta fs-12" v-show="statusTxt">{{ statusTxt }}</div>
      <div class="hover-mask d-flex align-center justify-center">
       <a-button type="primary" @click="onButtonClick">{{ buttonTxt }}</a-button>
      </div>
    </div>
    <a-card-meta class="text-left">
      <template slot="title">
        <p class="live-title fs-16 fw-bold">{{ options.liveName }}</p>
      </template>
      <template slot="description">
        <slot :options="options">
          <p class="author fs-14">主讲：{{ options.anchorTeacherName }}</p>
          <p class="date fs-14">时间：{{ options.liveStartTime }} ~ {{ options.liveEndTime }}</p>
        </slot>
      </template>
    </a-card-meta>
  </a-card>
</template>

<script>
import api from '@/api'
import './Live.scss'
export default {
  name: 'panel_live',
  data() {
    return {
    }
  },
  props: {
    options: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    // 1-直播中/2-未开始/3-已结束/4-未上课
    buttonTxt() {
      const { liveState = '' } = this.options
      let text = '进入直播'
      if (liveState === '1') {
        text = '进入直播'
      } else if (liveState === '2') {
        text = '直播未开始'
      } else if (liveState === '3') {
        text = '观看回放'
      } else if (liveState === '4') {
        text = '未上课'
      }
      return text
    },
    isShow(){
       if(this.options.liveImg){
         return false
       }
       return true
    },
    statusTxt() {
      const { liveState = '', countdown = '' } = this.options
      let text = ''
      if (liveState === '1' && Number(countdown) <= 0) {
        text = `直播进行中`
      } else if (liveState === '2' && Number(countdown) > 0) {
        text = `即将开始（${countdown}小时之后）`
      } else if (liveState === '3') {
        text = '看回放'
      } else if (liveState === '4') {
        text = '未上课'
      }
      return text
    }
  },
  methods: {
    async onButtonClick() {
      const { liveState = '', channelNo = '', openType = '', recordVideoUrl = '',isJoin = '',liveId='' } = this.options
      if (liveState === '1' || liveState === '3') {
        // 校验是否是小鱼易连直播
        let res = await api.student.checkXyLive({ liveId })
        if (res.data && !recordVideoUrl) {
            this.$message.warning('课程未导入直播地址')
            return;
        }

        const { data = {} } = await api.student.getLiveUrl({ liveState, channelNo, openType, recordVideoUrl, liveId })
        if (data) {
          // window.open(data)
          //记录观看次数
          if(isJoin==='N'){
             await api.student.updateLiveCount({liveId:liveId,studId:localStorage.getItem("userId")});
          }
          window.location.href = data
        } else {
          this.$message.warning('暂无直播地址')
        }
      } else if (liveState === '2' || liveState === '4') {
        this.$message.warning('直播未开始')
      }
    }
  }
}
</script>
