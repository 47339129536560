<!--
 * @Author: xiaziwen
 * @Date: 2021-01-07 16:09:57
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-09 10:54:26
 * @FilePath: \crm-education\src\components\business\course\Navigation.vue
-->
<template>
  <a-modal class="cus-modal course-nav-dialog" v-model="visible" width="640px" :footer="false">
    <template slot="title">
      <p class="text-center">学习导航</p>
    </template>
    <div class="d-flex justify-around align-center">
      <div
        class="nav-link-item hover-shadow link linear-gradient-dark-orange d-flex col justify-center align-center"
        @click="onLearn"
        v-if="info && info.crsStudyState === 'Y'"
      >
        <p class="desc1 fs-14 fw-bold">课程学习</p>
        <p class="desc2 fs-12">(已学{{ info ? info.learningCount : '- -' }}次)</p>
        <p class="mt-5 fs-14">进入学习</p>
      </div>
      <div
        class="nav-link-item hover-shadow link linear-gradient-blue d-flex col justify-center align-center"
        @click="onDiscuss"
        v-if="info && info.discussState === 'Y'"
      >
        <p class="desc1 fs-14 fw-bold">课程讨论</p>
        <p class="desc2 fs-12 opacity-0">填充</p>
        <p class="mt-5 fs-14">参与讨论</p>
      </div>
      <div
        class="nav-link-item hover-shadow link linear-gradient-light-orange d-flex col justify-center align-center"
        v-if="info && info.expandCrsState === 'Y'"
        @click="onExpand"
      >
        <p class="desc1 fs-14 fw-bold">录制课程</p>
        <p class="desc2 fs-12 opacity-0">填充</p>
        <p class="mt-5 fs-14">开始拓展</p>
      </div>
      <div
        class="nav-link-item hover-shadow link linear-gradient-light-blue d-flex col justify-center align-center"
        v-if="info && info.examWorkState === 'Y'"
      >
        <p class="desc1 fs-14 fw-bold">平时作业</p>
        <p class="desc2 fs-12 opacity-0">填充</p>
        <p class="mt-5 fs-14">开始考试</p>
      </div>
      <div
              class="nav-link-item hover-shadow link linear-gradient-red d-flex col justify-center align-center"
              @click="onShapeBook"
              v-if="info && info.shapeBookState === 'Y'"
      >
        <p class="desc1 fs-14 fw-bold">形考册子</p>
        <p class="desc2 fs-12 opacity-0">填充</p>
        <p class="mt-5 fs-14">下载册子</p>
      </div>

      <div
        class="nav-link-item hover-shadow link linear-gradient-purple d-flex col justify-center align-center"
        @click="onDownload"
        v-if="info && info.crsDataState === 'Y' && info.courseDataState ==='Y'"
      >
        <p class="desc1 fs-14 fw-bold">学习资料</p>
        <p class="desc2 fs-12 opacity-0">填充</p>
        <p class="mt-5 fs-14">下载资料</p>
      </div>
    </div>
    <p class="required pl-5 mt-10 fs-12" v-if="info && info.courseDescription">{{ info.courseDescription }}</p>
    <course-discuss-dialog ref="courseDiscussDialog"></course-discuss-dialog>
    <course-download-dialog ref="courseDownloadDialog"></course-download-dialog>
    <shape-download-dialog ref="shapeDownloadDialog"></shape-download-dialog>
    <course-authorization-dialog
      ref="courseAuthorizationDailog"
      @on-confirm="onAuthorizationConfirm"
    ></course-authorization-dialog>
    <wx-relation-dialog ref="wxRelationDialog"></wx-relation-dialog>
    <wx-public-dialog ref="wxPublicDialog"></wx-public-dialog>
  </a-modal>
</template>

<script>
import api from '@/api'
import CourseDiscussDialog from './Discuss'
import CourseDownloadDialog from './Download'
import ShapeDownloadDialog from './ShapeBook'
import CourseAuthorizationDialog from './Authorization'
import CryptoJS  from "crypto-js"
import WxRelationDialog from "../wx/RelationAccount";
import WxPublicDialog from "../wx/PublicAccount";

export default {
  name: 'course_navigation',
  components: {
      CourseDiscussDialog,
    CourseDownloadDialog,
    CourseAuthorizationDialog,
    ShapeDownloadDialog,
    WxRelationDialog,
    WxPublicDialog
  },
  data() {
    return {
      visible: false,
      info: null,
      validateVisible: false,
      isToxxw:'N'
    }
  },
  methods: {
    open(options = {}) {
      this.visible = true
      this.info = options
      // TODO:调式用
    },
    async onLearn() {
      // let res = await api.student.setLearnCount({ chooseId: this.info.chooseId })
      if (localStorage.getItem('ouchFirstLogin') == 0) {   
        const domain = localStorage.getItem('organizationId') || 100000
        let res = await api.protal.getOrganizationByDomain({ domain })
        this.isToxxw=res.data.isToxxw
        let res2 = await api.student.isSdRuleCode({forId: this.info.forId })
        let isSd = res2.data
          // 不是试点专业才弹出
        if(this.isToxxw==='Y' && !isSd){
          this.$refs.courseAuthorizationDailog.open(this.info)
          return null
        }   
      } 
      this.getCourseStudyUrl()
    },
    onDiscuss() {
      this.$refs.courseDiscussDialog.open(this.info)
    },
    async onDownload() {
      let organizationId = localStorage.getItem('organizationId');
      if (organizationId === '100000') {
          // 判断是否关注公众号
          let res = await api.student.checkSubscribeWx({organizationId: organizationId});
          if (res.data && !res.data.subscribe) {
              if (res.data.step === 1) {
                  this.$message.info(res.data.msg);
                  this.$refs.wxRelationDialog.open()
                  return;
              } else if (res.data.step === 2) {
                  this.$refs.wxPublicDialog.open()
                  return;
              }
          }
      }
      this.$refs.courseDownloadDialog.open(this.info)
    },
    onShapeBook(){
      this.$refs.shapeDownloadDialog.open(this.info)
    },
    onExpand() {
      // 拓展学习点击时间
      this.getExpandCrsUrl()
    },
    async getCourseStudyUrl() {
      const data = {
        courseCode: this.info.courseCode,
        courseName: this.info.courseName,
        courseUrl: this.info.courseUrl,
        forId: this.info.forId,
        signature: this.info.signature,
        organizationId: localStorage.getItem('organizationId'),
        studType : this.info.studType
      }
      let res = await api.student.getCourseStudyUrl(data)
      if (res.code === 200) {
        this.visible = false
        this.setLeaningCour()
        this.openNewWindow(this.decrypt(res.data))
      } else if (res.code === 1002&&this.isToxxw === 'Y') {
        this.$refs.courseAuthorizationDailog.open(this.info)
      }
    },
    decrypt(data){
      if(data.indexOf("http")>-1){
        return data
      }
      let s= CryptoJS.AES.decrypt(data, CryptoJS.enc.Latin1.parse("Lq9BrB7fePija*rQxL83RdD6"),{ mode: CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7}).toString(CryptoJS.enc.Utf8)
      return s.toString()
    },
    async getExpandCrsUrl() {
      const data = {
        chooseId: this.info.chooseId,
        courseClassId: this.info.courseClassId,
        courseId: this.info.zjCourseId || this.info.courseId,
        studCode: this.info.studCode,
        termCourseId: this.info.termCourseId,
        termId: this.info.termId
      }
      let res = await api.student.getExpandCrsUrl(data)
      if (res.code === 200) {
        this.openSelfCourseNewWindow(res.data)
      }
    },
    setLeaningCour() {
      // api.student.setLearnCount({ chooseId: this.info.chooseId })
    },
    async onAuthorizationConfirm(data = {}) {
      let res = await api.student.updateOuchPassword(data)
      if (res.code === 200) {
        localStorage.setItem('ouchFirstLogin', 1)
        this.getCourseStudyUrl()
      }
    },
    openNewWindow(url){
          // let toUrl = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(url))
          var newWindow = window.open()
          newWindow.location.href = url
    },
    openSelfCourseNewWindow(url){
       // let toUrl = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(url))
        var newWindow = window.open()
        let linkBasrUrl = process.env.VUE_APP_TEST_LINK_BASE_URL;
        newWindow.location.href = linkBasrUrl + url
    }
  }
}
</script>

<style lang="scss" scoped>
.course-nav-dialog {
  .nav-link-item {
    padding: 20px;
    width: 96px;
    margin: 5px 0;
    border-radius: 4px;
    overflow: hidden;
    color: #fff;
  }
}
</style>
