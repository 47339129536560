<!--
 * @Author: xiaziwen
 * @Date: 2021-01-08 09:27:59
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-08 10:20:14
 * @FilePath: \crm-education\src\components\business\course\Discuss.vue
-->
<template>
  <a-modal width="720px" class="cus-modal course-discuss-dialog" v-model="visible" :footer="false">
    <template slot="title">
      <p class="text-center">课程讨论</p>
    </template>
    <div class="comment">
      <a-textarea v-model="comment" placeholder="在这里发表评论与同学互动吧" :auto-size="{ minRows: 5, maxRows: 10 }" />
      <div class="d-flex justify-between mt-10">
        <p class="fs-12">还可以输入<span>100</span>字</p>
        <a-button size="small" class="cus-button" type="primary" @click="onDiscuss">发表</a-button>
      </div>
    </div>
    <div class="comment-list">
      <a-list item-layout="horizontal" :data-source="dataList" :pagination="paginationObject">
        <a-list-item slot="renderItem" slot-scope="item">
          <a-list-item-meta>
            <a-avatar slot="avatar" :src="item.studImg" />
            <template slot="title">
              <p class="fs-14">{{ item.studName || '佚名' }}</p>
              <p class="fs-12 color-gray">{{ item.createTime }} 发表</p>
            </template>
            <template slot="description">
              <p class="fs-14">{{ item.replayContnet }}</p>
            </template>
          </a-list-item-meta>
        </a-list-item>
      </a-list>
    </div>
  </a-modal>
</template>

<script>
import { message } from 'ant-design-vue'
import api from '@/api'

export default {
  name: 'course_discuss_dialog',
  data() {
    return {
      visible: false,
      comment: '',
      dataList: [{}, {}],
      paginationObject: {
        current: 1,
        total: 20,
        pageSize: 5,
        onChange: this.onPageChange
      },
      info: {}
    }
  },
  methods: {
    async open(info) {
      this.info = info
      await this.getCourseDiscuss(this.info)
      this.visible = true
    },
    onPageChange(page, pageSize) {
      this.paginationObject.current = page
      this.getCourseDiscuss(this.info)
    },
    async onDiscuss() {
      const data = {
        chooseId: this.info.chooseId,
        termCourseId: this.info.termCourseId,
        replayContnet: this.comment
      }
      let res = await api.student.setCourseDiscuss(data)
      if (res.code === 200) {
        message.success('提交成功')
        this.getCourseDiscuss(this.info)
      }
    },
    async getCourseDiscuss(info) {
      const termCourseId = info.termCourseId || '888888'
      let res = await api.student.getCourseDiscuss({
        termCourseId: termCourseId,
        pageSize: this.paginationObject.pageSize,
        currentPage: this.paginationObject.current
      })
      if (res.code === 200) {
        this.dataList = res.data[0]
        this.paginationObject.total = res.pageInfo.totalCount
        this.paginationObject.current = res.pageInfo.currentPage
      }
    }
  }
}
</script>
